import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "../Components/Screens/Home";
import AgroFarmers from "../Components/Screens/Home/agroFarmers";
import FarmerFarms from "../Components/Screens/Home/farmerFarms";

const CRouter = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
	},
    {
		path: "/:agroID",
		element: <AgroFarmers />,
	},
    {
		path: "/farmer/:farmerID",
		element: <FarmerFarms />,
	},
]);

export default CRouter;
