import { useState, useEffect } from "react";
import axios from "axios";
import { Routes, Route, useParams, Link } from 'react-router-dom';
import "./styles.css"

const AgroFarmers = () => {

    let { agroID } = useParams();

    console.log("useParams >>> ", agroID)


	useEffect(() => {
		getFarmers();
	}, []);

	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(250);
	const [farmers, setFarmers] = useState([]);

	const getFarmers = async () => {
        try {
            
       
		// const {data} = await axios.get(
		// 	`${process.env.REACT_APP_API_URL}farmers?page=${page}&limit=${limit}`
		// );

        const {data} = await axios.get(
			`${process.env.REACT_APP_API_URL}agro/${agroID}?page=${page}&limit=${limit}`
		);

        console.log("process.env.REACT_APP_API_URL >>> ", data.data.farmers)

		setFarmers(data.data.farmers);
    } catch (error) {
        console.log("Error >>> ", error.message)
            
    }
	};

	return (
		<div id="container">
			<h3>Agronomists Farmers</h3>
			<table id="customers">
				<thead>
					<tr>
						<td>S/N</td>
						<td>Name</td>
						<td>Email</td>
						<td>Sector</td>
						<td>Captured Farms</td>
						<td>Action</td>
					</tr>
				</thead>
				<tbody>
					{farmers.map((farmer, index) => (
						<tr key={farmer.id}>
							<td>{index + 1}</td>
							<td>
								{farmer.first_name} {farmer.last_name}
							</td>
							<td>{farmer.email}</td>
							<td>{farmer.sector ?? "farmer"}</td>
							<td>{farmer.farmsCount}</td>
							<td>
								{/* <button>View</button> */}
								<Link to={`/farmer/${farmer.id}`}>View</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default AgroFarmers;
